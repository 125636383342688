import React, { useEffect, useState } from "react";

import { styled, alpha } from "@mui/material/styles";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  SwipeableDrawer,
  List,
  ListItemText,
  ListItemButton,
  Paper,
  Button,
  Divider,
  Dialog,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// import LanguagePopover from "locales/LanguagePopover";
import LoginOrRegister from "components/LoginOrRegister";

// hooks
import useAuth from "contexts/AuthContext";
import { useTheme } from "@mui/material/styles";
import axios from "utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "@pankod/refine-react-router-v6";

export default function NavBar({
  title,
  noMenu,
  authentication = true,
  authenticationToolbar = false,
  shopInfo,
}) {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // login, register and alerts
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  // notifications
  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  //   const navigation = [
  //     // {
  //     //   label: "Profile",
  //     //   href: `/user/profile`,
  //     // },
  //     {
  //       label: "Orders",
  //       href: "/user/orders",
  //     },
  //     {
  //       label: "Settings",
  //       href: "/user/settings",
  //     },
  //   ];

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
      >
        My Account
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          navigate("/customer-login");
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        {/* TOOLBAR ---------------------------------------------------------------------- */}
        <Toolbar>
          {user && (
            <SwipeableDrawer
              open={isDrawerOpen}
              onOpen={() => setIsDrawerOpen(true)}
              onClose={() => setIsDrawerOpen(false)}
              PaperProps={{
                sx: { width: "60%" },
              }}
            >
              <List
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                {user && (
                  <>
                    {shopInfo && (
                      <Paper elevation={3} sx={{ m: 2, p: 2 }}>
                        <ListItemText
                          primary={title}
                          sx={{
                            mb: 2,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <hr />
                        <Button
                          fullWidth
                          sx={{ fontSize: 20 }}
                          onClick={() => {
                            if (shopInfo != null) {
                              navigate(`/shop/` + shopInfo.shop_id);
                            } else {
                              toast.error(
                                "Something went wrong, try and reload the page"
                              );
                            }
                          }}
                        >
                          HOME
                        </Button>
                      </Paper>
                    )}
                    <Paper elevation={3} sx={{ m: 2, p: 2 }}>
                      <ListItemText
                        primary={user.username}
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                      />
                      <hr />
                      <ListItemButton
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        <ListItemText
                          primary="Profile"
                          sx={{ textAlign: "center" }}
                        />
                      </ListItemButton>
                    </Paper>
                  </>
                )}
                <Box sx={{ flexGrow: 1 }} />
                {authenticationToolbar && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        if (user) {
                          logout();
                          navigate("/login");
                        } else {
                          setIsLoginDialogOpen(true);
                        }
                      }}
                    >
                      {user ? "LOGOUT" : "LOGIN"}
                    </Button>
                  </Box>
                )}
              </List>
            </SwipeableDrawer>
          )}

          {/* APP BAR ---------------------------------------------------------------------- */}
          {!noMenu && user && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {title ?? "Title"}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* <LanguagePopover /> */}
          {/* {user && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show new messages"
                color="inherit"
              >
                <Badge badgeContent={messages.length} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show new notifications"
                color="inherit"
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          )} */}

          {/* {user && (
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          )} */}
          {authentication && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 2,
              }}
            >
              <Button
                variant="text"
                onClick={async () => {
                  if (user) {
                    logout();
                    navigate("/login");
                    // window.location.reload();
                  } else {
                    setIsLoginDialogOpen(true);
                  }
                }}
                sx={{
                  color: "white",
                }}
              >
                {user ? "LOGOUT" : "LOGIN/REGISTER"}
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* {renderMobileMenu} */}
      {renderMenu}

      {/* ===== LOGIN OR REGISTER DIALOG ===== */}
      <Dialog
        open={isLoginDialogOpen}
        onClose={() => setIsLoginDialogOpen(false)}
      >
        <LoginOrRegister
          onComplete={() => {
            setIsLoginDialogOpen(false);
            toast.success("You are logged in!");
          }}
          onError={(error) => {
            console.log(error);
            const errMessage = error?.message;
            const emailError = errMessage === "User already exists";

            toast.error(
              emailError || errMessage || "There was an error logging in"
            );
          }}
        />
      </Dialog>
    </Box>
  );
}
